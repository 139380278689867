<template>
  <div style="width: auto">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
<!--      <a-button type="primary" @click="$refs.dialog.isShow = true">新建</a-button>-->
    </Breadcrumb>
    <!-- 查询 -->
    <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.orderNo" placeholder="订单编号"/>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.productName" placeholder="货品名称"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="(e)=>{queryData()}">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm">重置</a-button>
      </a-form-item>
    </a-form>
    <br/>
    <a-table :columns="columns" :data-source="list" :rowKey='record=>record.id'
             :pagination="false"
             :scroll="{ x: 1700, y: 580 }"
             bordered>
       <span slot="action1" slot-scope="scope">
        <div style="color: #4f9efa">{{scope.id}}</div>
        <div style="color: #4f9efa">类型:{{scope.typeName}}</div>
        <div style="color: #4f9efa">状态: {{scope.statusName}}</div>
      </span>
      <span slot="action3" slot-scope="scope">
        <a  @click="zoom(scope.imgUrl)">
          <a-avatar shape="square"
            style="width:84px;height:84px;float: left"
            :src="scope.imgUrl" />
        </a>
        <div style="margin-top: 2px;margin-left: 90px;">
          {{ scope.productName }}({{ scope.spec }}*{{ scope.qty }})
        </div>
        <div style="margin-top: 2px;margin-left: 90px;">
          ￥{{ scope.price }}
        </div>
      </span>
      <span slot="action4" slot-scope="scope">
        <span style="width:80px;float: left;">{{ scope.memo }}</span>
        <span style="margin-left:82px;float: left;">
          <span v-for="(imgSale,index) in scope.saleImages" :key="index">
            <a  @click="zoom(imgSale)">
              <a-avatar shape="square"
                style="width:80px;height:80px;margin-left:5px;margin-top:2px;"
                :src="imgSale" />
            </a>
          </span>
        </span>
      </span>
      <span slot="action5" slot-scope="scope">
        <div v-for="(item,index) in scope.saleMemos" :key="index">
          {{ item.gmtModified }} &emsp;{{ item.memo }}
        </div>
      </span>
       <span slot="action6" slot-scope="scope">
        <div>申请时间：{{ scope.gmtCreate }}</div>
        <div v-if="scope.gmtCancel!=null">取消时间： {{ scope.gmtCancel }}</div>
        <div v-if="scope.gmtReceive!=null">收货时间： {{ scope.gmtReceive }}</div>
         <div v-if="scope.gmtAudit!=null">审核时间： {{ scope.gmtAudit }}</div>
        <div v-if="scope.gmtComplete!=null">完成时间： {{ scope.gmtComplete }}</div>
      </span>
    </a-table>
    <br/>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%" :src="goodsImag"/>
    </a-modal>
    <!-- 分页 -->
    <a-pagination
      show-size-changer
      show-quick-jumper
      style="text-align: center"
      :total="total"
      :show-total="
        (total, range) => `共${total}条记录 第${range[0]}-${range[1]}条数据`
      "
      :page-size="pageSize"
      v-model="current"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    />
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      form: {
        orderNo: '',
        productName: ''
      },
      visible: false,
      goodsImag: '',
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      pageSize: 10,
      current: 1,
      breadList: [
        {
          name: '售后管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      list: [],
      columns: [
        { title: '售后单号', dataIndex: '', key: '', width: 140, scopedSlots: { customRender: 'action1' } },
        { title: '订单信息', dataIndex: 'orderNo', key: 'orderNo', width: 120 },
        { title: '商品信息', dataIndex: '', key: '', width: 320, scopedSlots: { customRender: 'action3' } },
        { title: '补充说明', dataIndex: '', key: '', width: 380, scopedSlots: { customRender: 'action4' } },
        { title: '审批说明', dataIndex: 'auditMemo', key: 'auditMemo', width: 200 },
        { title: '备注', dataIndex: '', key: '', scopedSlots: { customRender: 'action5' }, width: 280 },
        { title: '申请时间', scopedSlots: { customRender: 'action6' }, key: '', width: 260 }
      ]
    }
  },
  created () {
    this.queryData()
  },
  methods: {
    // 重置表单
    resetForm () {
      this.form = {
        orderNo: '',
        productName: ''
      }
      this.queryData()
    },
    // 是否确认弹窗口  方法
    confirm (scope) {
      this.del(scope)
    },
    cancel (values) {
      console.log(values)
    },
    // 改变页码
    onPageChange (pageNumber, pageSize) {
      this.queryData(pageNumber)
    },
    // 改变页码
    onShowSizeChange (current, pgSize) {
      console.log(pgSize)
      this.pageSize = pgSize
      this.queryData()
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.queryData()
    },
    async queryData (pageNumber) {
      // 如果不是点击的分页  而是点的查询按钮  需要修改  当前页current
      this.current = pageNumber ? this.current : 1
      console.log(this.current, this.pageSize)
      const res = await this.$store.dispatch('http', {
        api: 'hisAfterSaleOrder',
        params: {
          ...this.form,
          pageNo: this.current,
          pageSize: this.pageSize
        }
      })
      this.list = res.records
      this.total = res.total
      this.$forceUpdate()
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
      this.queryData()
    },
    handleCancel (e) {
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.goodsImag = scope
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
